<template>
  <div class="plk_layercreator">
    <Menu></Menu>
    <div class="viewer">
      <h5>{{$t('designer.cms.layers.creator.title')}}</h5>
      <ol class="wizard">
        <li v-for="(step, index) in steps" :key="index" tabindex="1"
          class="wizard__item"
          v-on:click="setStep(step.id)"
          v-bind:title="$t(step.title)"
          v-bind:class="{
          'wizard__item--visited': index < actualStepPosition,
          'wizard__item--disabled': !step.enable,
          'wizard__item--active': actualStep.id === step.id
          }">
            <span class="wizard__item-desc">
              {{ $t(step.title) }}
            </span>
          <span class="wizard__item-arrows"></span>
        </li>
      </ol>
      <div class="mb-4" v-html="$t(actualStep.desc)"></div>
      <AbstractionCreator v-if="actualStep.id === 1" :abstraction="abstraction" @updateAbstraction="updateAbstraction" class="twelve columns"/>
      <TablesEditor :tables="this.abstraction.tables" v-if="actualStep.id === 2" @updateTables="updateTables"/>
      <Uploader v-if="actualStep.id === 3" class="mt-3" :simpleFile="true" acceptType="application/json" @loadFile="loadedFile"></Uploader>
      <LayerGenerator :abstraction="abstraction" v-if="actualStep.id === 4" @onInit="published=false" @cancel="published=false" @generated="generatedLayer"/>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import i18n from './i18n'
import Menu from '../../components/menus/Menu'
import mixincms from '@/mixins/cms'
import AbstractionCreator from './components/AbstractionCreator'
import TablesEditor from './components/TableEditor'
import LayerGenerator from './components/LayerGenerator'
import Uploader from '@/views/design/components/uploaders/Uploader'

export default {
  name: 'layercreator',
  components: {
    Menu,
    AbstractionCreator,
    TablesEditor,
    LayerGenerator,
    Uploader
  },
  mixins: [mixincms],
  data () {
    return {
      actualStepPosition: 0,
      published: false,
      abstraction: {
        name: undefined,
        connection: undefined,
        type: undefined,
        folderConnection: this.$route.query.folder || 'ROOT',
        folderAbstraction: this.$route.query.folder || 'ROOT',
        tables: []
      }
    }
  },
  computed: {
    steps () {
      return [
        { id: 1, title: 'designer.cms.layers.creator.createlayer.title', desc: 'designer.cms.layers.creator.createlayer.description', enable: !this.published },
        { id: 2, title: 'designer.cms.layers.creator.createtables.title', desc: 'designer.cms.layers.creator.createtables.description', enable: this.abstraction.name && this.abstraction.connection && this.abstraction.folderConnection && this.abstraction.folderAbstraction && !this.published },
        { id: 3, title: 'designer.cms.layers.creator.uploadfile.title', desc: 'designer.cms.layers.creator.createtables.description', enable: this.abstraction.name && this.abstraction.connection && this.abstraction.folderConnection && this.abstraction.folderAbstraction && !this.published },
        { id: 4, title: 'designer.cms.layers.creator.publishlayer.title', desc: 'designer.cms.layers.creator.publishlayer.description', enable: this.abstraction.tables.length > 0 && !this.published },
        { id: 5, title: 'designer.cms.layers.creator.publishedlayer.title', desc: 'designer.cms.layers.creator.publishedlayer.description', enable: this.published }
      ]
    },
    actualStep () {
      return this.steps[this.actualStepPosition]
    },
    filename () {
      return `${this.abstraction.name}.json`
    }
  },
  methods: {
    onInitComponent () {
      this.$servicesPLK.translation.init(i18n, { overwrite: false })
    },
    async loadedFile ({ content }) {
      await this.generatedLayer({ content: content.data })
      this.$nextTick(() => {
        this.setStep(5)
      })
    },
    async generatedLayer ({ content }) {
      try {
        await this.$servicesPLK.cms.createLayerInFolder(this.abstraction.folderAbstraction, {
          name: this.filename,
          connection: this.abstraction.connection,
          type: this.abstraction.type,
          file: new File([content], `${this.abstraction.name}.json`, { type: 'text/plain' })
        })
        this.published = true
        this.$servicesPLK.event.emitEvent(this.$servicesPLK.event.BASIC_ACTIONS.ERROR, {
          type: 'success',
          msg: this.$t('designer.cms.layers.creator.dialog.publishlayer.finish')
        })
      } catch (e) {
        this.$servicesPLK.event.emitEvent(this.$servicesPLK.event.BASIC_ACTIONS.ERROR, {
          type: 'error',
          msg: this.$t('designer.cms.layers.creator.dialog.publishlayer.error')
        })
      }
    },
    setStep (stepId) {
      const indexStep = this.steps.findIndex(step => step.id === stepId)
      if (indexStep > -1 && this.steps[indexStep].enable) {
        this.actualStepPosition = indexStep
        Vue.nextTick(() => {
          if (this.steps[indexStep].onChange) {
            this.steps[indexStep].onChange()
          }
        })
      }
    },
    updateAbstraction ({ abstraction }) {
      abstraction.tables = this.abstraction.tables
      this.abstraction = abstraction
    },
    updateTables ({ tables }) {
      Vue.set(this.abstraction, 'tables', tables)
    }
  }
}
</script>
<style scoped lang="scss">
  .plk_layercreator {
    background: #f8f9fa;
    height: 100vh;
    .viewer {
      padding: 3vh;
      padding-left: calc(3vh + 50px);
      .wizard {
        list-style: none;
        margin: 0;
        padding: 0;
        counter-reset: bth-wizard-steps;
        display: flex;
        flex-wrap: no-wrap;
        overflow: hidden;

        .wizard__item-arrows {

          &:after,
          &:before {
            content: " ";
            display: block;
            position: absolute;
          }

          &:before {
            border-top: 30px solid transparent;
            border-bottom: 30px solid transparent;
            border-left: 20px solid white; // gray l20
            top: -4px;
            left: -1px;
            z-index: 1;
          }

          &:after {
            border-top: 26px solid transparent;
            border-bottom: 26px solid transparent;
            border-left: 18px solid #EBEDF0; // gray l20
            top: 0;
            right: -17px;
            left: auto;
            z-index: 2;
          }
        }

        .wizard__item {
          background-color: #EBEDF0; // gray l20
          color: #595959; //gray d20
          counter-increment: bth-wizard-steps;
          flex-grow: 1;
          font-size: 13px;
          font-weight: 600;
          line-height: 50px;
          min-width: 30px;
          max-width: 100%;
          text-transform: uppercase;
          display: flex;
          align-items: center;
          flex-wrap: no-wrap;
          padding-left: 34px;
          padding-right: 8px;
          cursor: pointer;
          position: relative;

          &:before {
            content: counter(bth-wizard-steps);
            font-size: 14px;
            font-weight: 600;
            min-width: 30px;
            display: flex;
            width: 30px;
            height: 30px;
            background-color: white; // gray l40
            margin-right: 8px;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
          }

          &:first-child {
            padding-left: 8px;
            .wizard__item-arrows {
              &:before {
                display:none;
              }
            }
          }

          &:last-child {
            .wizard__item-arrows {
              &:after {
                display:none;
              }
            }
          }

          &-desc {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          &--active {
            background-color: #7FAADC; // blue l20
            color: #222; // gray d30
            min-width: auto;
            &:focus {
              outline: none;
            }
            .wizard__item-arrows {
              &:after {
                border-left-color: #7FAADC; // blue l20
              }
            }
            &:before {
              color: #295D99 ; //blue d10
            }
          }

          &--visited {
            background-color: #CFDFF2; // blue l40
            color: #595959; // gray d20
            &:focus {
              outline: none;
            }
            .wizard__item-arrows {
              &:after {
                border-left-color: #CFDFF2; // blue l40
              }
            }

            &:before {
              font-family: 'Font Awesome 5 Free';
              color: #295D99 ; //blue d10
              content: '\f00c';
              font-size: 18px;
              text-transform: none;
            }
          }

          &--disabled, [disabled] {
            background-color: #F5F7FA; // gray l30
            color: #c0c0c0; // gray d20
            cursor: default;
            &:focus {
              outline: none;
            }
            .wizard__item-arrows {
              &:after {
                border-left-color: #F5F7FA; // gray l30
              }
            }

            &:before {
              color: #c0c0c0 ; //gray
              content: counter(bth-wizard-steps);
            }
          }
        }
      }
    }
  }
</style>
