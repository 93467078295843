<template>
  <div class="plk_update_table">
    <table class="u-full-width">
      <thead>
      <tr>
        <th>{{$t('designer.cms.layers.creator.table.type')}}</th>
        <th>{{$t('designer.cms.layers.creator.table.visiblename')}}</th>
        <th>{{$t('designer.cms.layers.creator.table.keycolumn')}}</th>
        <th colspan="2">{{$t('designer.cms.layers.creator.table.config')}}</th>
        <th class="text-center">{{$t('designer.cms.layers.creator.table.options')}}</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(row, key) in (fields || [])" :key="key">
        <td class="typeOption">
          <!-- (Todo) Tipo -->
          <switches :text-enabled="$t('designer.cms.layers.creator.table.switch.dimension')" :text-disabled="$t('designer.cms.layers.creator.table.switch.measure')" v-model="row.isDimension" color="blue"></switches>
        </td>
        <td>
          <!-- (Todos) Nombre de columna -->
          <input type="text" class="u-full-width" :placeholder="$t('designer.cms.layers.creator.table.visiblename')" v-model="row.nameColumn">
        </td>
        <td v-if="row.isDimension">
          <!-- (Dimension) Nombre de columna en tabla -->
          <input type="text" class="u-full-width six columns" :placeholder="$t('designer.cms.layers.creator.table.keycolumn')" v-model="row.keyColumn">
          <!-- relations with column -->
          <v-select class="u-full-width six columns mt-3" :title="$t('designer.cms.layers.creator.tablerelation.title')" id="tablerelation" :options="fields2relation" label="prettyname" v-model="row.relations" multiple/>
        </td>
        <td v-if="!row.isDimension">
          <!-- (Métrica) Operación y columna en tabla -->
          <select class="u-full-width six columns" v-model="row.operation" required>
            <option :value="option" v-for="(option, key) in operations" :key="key">{{$t(`designer.cms.layers.creator.table.${option}`)}}</option>
          </select>
          <input type="text" class="u-full-width six columns" :placeholder="$t('designer.cms.layers.creator.table.keycolumn')" v-model="row.keyColumn">
        </td>
        <td colspan="2" v-if="!row.isDimension">
          <!-- (Métrica) Links -->
          <input type="text" class="u-full-width twelve" :placeholder="$t('designer.cms.layers.creator.table.formatString')" v-model="row.formatString">
        </td>
        <td colspan="2" v-if="row.isDimension" class="text-justify">
          <!-- (Dimensión) visibilidad -->
          <switches :text-enabled="$t('designer.cms.layers.creator.table.switch.visible')" :text-disabled="$t('designer.cms.layers.creator.table.switch.invisible')" v-model="row.visible" color="green"></switches>
          <!-- (Dimensión) es pk de la tabla -->
          <switches class="ml-5" :disabled="!row.isDimension" :text-enabled="$t('designer.cms.layers.creator.table.switch.isPk')" :text-disabled="$t('designer.cms.layers.creator.table.switch.noPk')" v-model="row.isPk" color="blue"></switches>
        </td>
        <td class="text-center">
          <!-- (Todos) opciones -->
          <i :title="$t('designer.cms.layers.creator.table.deletecolumn')" class="fas fa-trash pointer" @click="deleteColumn(key)"></i>
        </td>
      </tr>
      <tr class="btn" @click="addNewColumn()">
        <td colspan="6" class="text-center pointer">
          {{$t('designer.cms.layers.creator.table.createcolumn')}}
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import Vue from 'vue'
import _ from 'lodash'
import Switches from 'vue-switches'
import mixincms from '@/mixins/cms'
import vSelect from 'vue-select'

export default {
  name: 'tableupdate',
  props: {
    fields2relation: {
      required: true
    },
    table: {
      required: true
    }
  },
  components: {
    Switches,
    vSelect
  },
  mixins: [mixincms],
  data () {
    return {
      startingFields: false,
      startingRelations: false,
      operations: [
        'COUNT',
        'SUM',
        'ACCUMULATED',
        'COUNT_DISTINCT',
        'MIN',
        'MAX',
        'AVG'
      ],
      elementsConnection: [],
      fields: _.cloneDeep(this.table.fields || [])
    }
  },
  methods: {
    addNewColumn () {
      this.fields.push({
        nameColumn: '',
        keyColumn: '',
        formatString: '#,###',
        visible: true,
        isDimension: true,
        isPk: false,
        relations: []
      })
    },
    deleteColumn (columnIndex) {
      if (this.fields && columnIndex > -1 && this.fields.length > columnIndex) {
        this.$modal.show('dialog', {
          title: this.$t('designer.cms.layers.creator.dialog.deleteColumn.title'),
          text: this.$t('designer.cms.layers.creator.dialog.deleteColumn.text', { name: this.fields[columnIndex].nameColumn }),
          buttons: [
            {
              title: this.$t('designer.cms.layers.creator.dialog.cancel'),
              handler: () => {
                this.$modal.hide('dialog')
              }
            },
            {
              title: this.$t('designer.cms.layers.creator.dialog.confirm'),
              handler: () => {
                this.fields.splice(columnIndex, 1)
                this.$modal.hide('dialog')
              }
            }
          ]
        })
      }
    },
    updateTable () {
      const table = { ...this.table, fields: _.cloneDeep(this.fields) }
      this.$emit('updateTable', { table })
    }
  },
  watch: {
    table () {
      Vue.set(this, 'startingFields', true)
      Vue.set(this, 'fields', _.cloneDeep(this.table.fields || []))
      Vue.set(this, 'startingRelations', true)
    },
    fields: {
      handler (fields) {
        if (!this.startingRelations) {
          this.updateTable()
        }
        Vue.set(this, 'startingRelations', false)
      },
      deep: true
    }
  }
}
</script>
<style scoped lang="scss">
  .plk_update_table {
    table {
      input, textarea, select, fieldset {
        padding: .8rem;
        margin-top: 1rem;
        margin-bottom: 0px;
      }
    }

    tr.btn:hover {
      background: #efefef;
    }
  }
</style>
