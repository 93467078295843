<template>
  <div class="plk_create_abstraction">
    <!-- Folder Layer -->
    <FolderList cancreate :initial="newAbstraction.folderAbstraction" class="six" title="designer.cms.layers.creator.folderlayer" @onChange="updateFolderAbstraction"/>
    <!-- Layer name -->
    <div class="six columns">
      <label for="layername">{{$t('designer.cms.layers.creator.layername')}}</label>
      <input id="layername" required type="text" class="u-full-width twelve columns"
             :placeholder="$t('designer.cms.layers.creator.tablename')" v-model="newAbstraction.name">
    </div>
    <!-- Folder Connection-->
    <FolderList :initial="newAbstraction.folderConnection" class="six" title="designer.cms.layers.creator.selectorfolderconnection" @onChange="updateFolderConnection"/>
    <!-- Connection -->
    <div class="six columns">
      <label for="selectElementConnection">{{$t('designer.cms.layers.creator.selectorconnection')}}</label>
      <select id="selectElementConnection" required class="u-full-width" v-model="newAbstraction.connection">
        <option :value="option.resource.id" v-for="(option, key) in elementsConnection" :key="key">
          {{option.resource.name}}
        </option>
      </select>
    </div>
    <!-- type -->
    <!-- Temporalmente ocultamos el selector de type ya que no tenemos más que un tipo y generaría confusión al usuario -->
    <!-- <div class="twelve columns">
      <label for="selectTypeFile">{{$t('designer.cms.layers.creator.selectortype')}}</label>
      <select id="selectTypeFile" required class="u-full-width" v-model="newAbstraction.type">
        <option :value="type" v-for="(type, key) in types" :key="key">
          {{key}}
        </option>
      </select>
    </div> -->
  </div>
</template>

<script>
import FolderList from '../../components/FolderList'
import mixincms from '@/mixins/cms'
import _ from 'lodash'

export default {
  name: 'createabstraction',
  components: {
    FolderList
  },
  mixins: [mixincms],
  props: {
    abstraction: {
      required: true
    }
  },
  data () {
    return {
      startingTable: false,
      elementsConnection: [],
      types: {
        Plakazione: 'eu.argalladas.plaka.commons.abstraction.quickquery.QuickAbstraction'
      },
      newAbstraction: {
        name: this.abstraction.name,
        type: 'eu.argalladas.plaka.commons.abstraction.quickquery.QuickAbstraction',
        connection: this.abstraction.connection,
        folderConnection: this.abstraction.folderConnection,
        folderAbstraction: this.abstraction.folderAbstraction
      }
    }
  },
  methods: {
    onInitComponent () {
      this.loadElementsConnection()
    },
    async loadElementsConnection () {
      this.elementsConnection = await this.getElementsConnection(this.newAbstraction.folderConnection)
    },
    updateFolderAbstraction (folder) {
      this.newAbstraction.folderAbstraction = folder
    },
    updateFolderConnection (folder) {
      this.newAbstraction.folderConnection = folder
    }
  },
  watch: {
    'newAbstraction.folderConnection' () {
      this.loadElementsConnection()
    },
    abstraction () {
      this.startingTable = true
      this.newAbstraction = {
        name: this.abstraction.name,
        type: this.abstraction.type,
        connection: this.abstraction.connection,
        folderConnection: this.abstraction.folderConnection,
        folderAbstraction: this.abstraction.folderAbstraction
      }
    },
    newAbstraction: {
      handler () {
        if (!this.startingTable) {
          const abstraction = _.cloneDeep(this.newAbstraction)
          this.$emit('updateAbstraction', { abstraction })
        }
        this.startingTable = false
      },
      deep: true
    }
  }
}
</script>
<style scoped lang="scss">
</style>
