<template>
  <div class="plk_tableseditor">
      <label for="selectTable">{{$t('designer.cms.layers.creator.selectortable.title')}}</label>
      <sup class="pull-right pointer" v-if="table2show != -1" @click="deleteTable(table2show)"><i :title="$t('designer.cms.layers.creator.table.delete')" class="fas fa-trash"></i> {{$t('designer.cms.layers.creator.table.delete')}}</sup>
      <select class="u-full-width" id="selectTable" v-model="table2show">
        <option value="-1">{{$t('designer.cms.layers.creator.selectortable.createtable')}}</option>
        <option :value="key" v-for="(option, key) in newTables" :key="key">{{$t('designer.cms.layers.creator.selectortable.edit', { name: option.id })}}</option>
      </select>
    <TableCreator @newTable="createTable" class="twelve columns" v-if="table2show == -1"></TableCreator>
    <TableUpdate :table="newTables[table2show]" :fields2relation="fields2relation" @updateTable="updateTable" v-else-if="newTables[table2show]" class="twelve columns"></TableUpdate>
  </div>
</template>

<script>
import Vue from 'vue'
import _ from 'lodash'
import mixincms from '@/mixins/cms'
import TableCreator from './TableCreator'
import TableUpdate from './TableUpdate'

export default {
  name: 'tableeditor',
  components: {
    TableCreator,
    TableUpdate
  },
  props: {
    tables: {
      required: true
    }
  },
  mixins: [mixincms],
  data () {
    return {
      table2show: -1,
      newTables: _.cloneDeep(this.tables)
    }
  },
  computed: {
    fields2relation () {
      const relations = []
      this.newTables.forEach(table => table.fields.forEach(field => {
        if (field.keyColumn && field.isDimension && field.isPk && table.id !== this.newTables[this.table2show].id) {
          relations.push({ ...field, tableid: table.id, tablename: table.name, prettyname: `${table.id}.${field.nameColumn}` })
        }
      }))
      return relations
    }
  },
  methods: {
    createTable ({ table }) {
      Vue.set(this, 'table2show', this.newTables.length)
      this.newTables.push(table)
      this.$emit('updateTables', { tables: this.newTables })
    },
    updateTable ({ table }) {
      Vue.set(this.newTables, this.table2show, table)
      this.$emit('updateTables', { tables: this.newTables })
    },
    deleteTable (tableIndex) {
      if (tableIndex > -1 && this.newTables.length > tableIndex) {
        this.$modal.show('dialog', {
          title: this.$t('designer.cms.layers.creator.dialog.deleteTable.title'),
          text: this.$t('designer.cms.layers.creator.dialog.deleteTable.text', { name: this.newTables[tableIndex].name }),
          buttons: [
            {
              title: this.$t('designer.cms.layers.creator.dialog.cancel'),
              handler: () => {
                this.$modal.hide('dialog')
              }
            },
            {
              title: this.$t('designer.cms.layers.creator.dialog.confirm'),
              handler: () => {
                this.newTables.splice(tableIndex, 1)
                Vue.set(this, 'table2show', -1)
                this.$modal.hide('dialog')
              }
            }
          ]
        })
      }
    }
  },
  watch: {
    tables () {
      this.newTables = _.cloneDeep(this.tables)
    }
  }
}
</script>
<style scoped lang="scss">
</style>
