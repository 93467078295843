<template>
  <div class="plk_layer_generator p-3">
    <div v-for="(item, index) in status" :key="index" v-html="$t(item)">
    </div>
  </div>
</template>

<script>
import mixincms from '@/mixins/cms'

export default {
  name: 'layergenerator',
  props: {
    abstraction: {
      required: true
    }
  },
  mixins: [mixincms],
  data () {
    return {
      status: []
    }
  },
  created () {
    this.$emit('onInit')
    this.status = []
    this.publishLayer()
  },
  methods: {
    publishLayer () {
      this.$modal.show('dialog', {
        title: this.$t('designer.cms.layers.creator.dialog.publishlayer.title'),
        text: this.$t('designer.cms.layers.creator.dialog.publishlayer.text'),
        buttons: [
          {
            title: this.$t('designer.cms.layers.creator.dialog.cancel'),
            handler: () => {
              this.cancelLayerCreatorProccess()
              this.$modal.hide('dialog')
            }
          },
          {
            title: this.$t('designer.cms.layers.creator.dialog.confirm'),
            handler: () => {
              this.executeLayerCreatorProccess()
              this.$modal.hide('dialog')
            }
          }
        ]
      })
    },
    cancelLayerCreatorProccess () {
      this.status.push('designer.cms.layers.creator.dialog.publishlayer.status.cancelbyuser')
      this.$emit('cancel')
    },
    validations () {
      this.abstraction.tables.forEach(table => {
        const fieldPk = table.fields.find(field => field.isPk)
        if (!fieldPk) {
          this.status.push(this.$t('designer.cms.layers.creator.dialog.publishlayer.status.notfoundpk', { table: table.name }))
          throw new Error(this.$t('designer.cms.layers.creator.dialog.publishlayer.status.notfoundpk', { table: table.name }))
        }
      })
    },
    cleanIds (name = '') {
      return name.replace(/[^\w]/gi, '').toLowerCase()
    },
    generateLayerFileContent () {
      const generated = {}
      this.abstraction.tables.forEach(table => {
        const tableId = this.cleanIds(table.id)
        generated[tableId] = this.generateStatistics(table)
      })
      return JSON.stringify({ statistics: generated }, null, 2)
    },
    generateStatistics (table) {
      if (table) {
        const tableId = this.cleanIds(table.id)
        const isSelectQuery = (table.name || '').toLowerCase().match('^select .*$') !== null
        return {
          hash: tableId,
          table: isSelectQuery ? undefined : table.name,
          sql: isSelectQuery ? table.name : undefined,
          dimensions: this.generateDimensions(table.fields.filter(field => field.isDimension)),
          measures: this.generateMeasures(table.fields.filter(field => !field.isDimension)),
          joins: this.generateJoins(table.fields.filter(field => field.isDimension))
        }
      }
      throw new Error('Not found table')
    },
    generateDimensions (fields = []) {
      const dimensions = {}
      fields.filter(field => field.isDimension).forEach(field => {
        const tableId = this.cleanIds(field.keyColumn)
        dimensions[tableId] = {
          hash: this.cleanIds(field.keyColumn),
          sql: field.keyColumn,
          visible: field.visible,
          primary: field.isPk,
          label: field.nameColumn
        }
      })
      return dimensions
    },
    generateMeasures (fields = []) {
      const measures = {}
      fields.filter(field => !field.isDimension).forEach(field => {
        const tableId = this.cleanIds(field.keyColumn)
        measures[tableId] = {
          hash: this.cleanIds(field.keyColumn),
          label: field.nameColumn,
          sql: field.keyColumn,
          visible: field.visible,
          agregator: field.operation,
          format: field.formatString
        }
      })
      return measures
    },
    generateJoins (fields = []) {
      const joins = {}
      fields.filter(field => field.isDimension && field.relations && field.relations.length).forEach(field => {
        field.relations.forEach(relation => {
          const tableId = this.cleanIds(relation.tableid)
          joins[tableId] = {
            relationship: 'LeftJoin',
            relation: this.cleanIds(field.keyColumn),
            dimension: this.cleanIds(relation.keyColumn)
          }
        })
      })
      return joins
    },
    executeLayerCreatorProccess () {
      this.status.push('designer.cms.layers.creator.dialog.publishlayer.status.initprocess')
      this.validations()
      const generatedFile = this.generateLayerFileContent()
      this.status.push('designer.cms.layers.creator.dialog.publishlayer.status.generatedlayer')
      this.$emit('generated', { content: generatedFile })
    }
  }
}
</script>
<style scoped lang="scss">
  .plk_layer_generator {
    background: black;
    color: white;
    font-family: monospace;
    height: 70vh;
    overflow: scroll;

    ::v-deep .red {
      color: #ff6464 !important;
    }
    ::v-deep .orange {
      color: orange !important;
    }
    ::v-deep .green {
      color: #92f792 !important;
    }
  }
</style>
