<template>
  <form class="plk_create_table" @submit.prevent="createtable">

    <label for="aliastable">{{$t('designer.cms.layers.creator.tableconfig')}}</label>
    <div>
      <input id="aliastable" required type="text" class="u-full-width five columns"
               :placeholder="$t('designer.cms.layers.creator.tablealias')" v-model="newTable.id">
      <input id="selectTable" required type="text" class="u-full-width five columns"
             :placeholder="$t('designer.cms.layers.creator.tablename')" v-model="newTable.name">
      <button type="submit" class="two columns">{{$t('designer.cms.layers.creator.createtable')}}</button>
    </div>
  </form>
</template>

<script>
import _ from 'lodash'
import mixincms from '@/mixins/cms'

export default {
  name: 'createtable',
  mixins: [mixincms],
  data () {
    return {
      newTable: {
        name: '',
        fields: []
      }
    }
  },
  methods: {
    createtable () {
      const table = _.cloneDeep(this.newTable)
      this.newTable.id = ''
      this.newTable.name = ''
      this.newTable.connection = ''
      this.$emit('newTable', { table })
    }
  }
}
</script>
<style scoped lang="scss">
</style>
